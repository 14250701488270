.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  margin-top: auto;
  margin-bottom: 20px;
}

.loginBtn {
  margin-top: 20px;
  margin-bottom: auto;
}

.auth .loginBtn {
  max-height: unset;
}

.version {
  margin-top: auto;
}
