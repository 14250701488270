.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  font-family: var(--font-family-primary);

  max-height: var(--max-button-height);
}

.button:active {
  outline: none;
}

.themePrimary {
  background: #00acef;
  color: #fff;
}

.themeDanger {
  background: red;
  color: #fff;
}

.themePrimary:hover {
  background: #005eef;
}

.themeDanger:hover {
  background: #aa0606;
}

.themePrimary[disabled] {
  background: #00acef70;
}

.themeDanger[disabled] {
  background: #c27b7b;
}

.themePrimary:active,
.themePrimaryPressed {
  background: #001162;
}

.themeDanger:active,
.themeDangerPressed {
  background: #820808;
}
