.wrapper {
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.wrapperDisabled {
  opacity: 0.5;
  user-select: none;
}

.controls {
  display: grid;
  grid-template-columns: 150px minmax(auto, 150px) repeat(2, 30px) 1fr 1fr;
  gap: 10px;
  align-items: center;
}

.saveBtn {
  max-width: 150px;
  justify-self: end;
}

.roleOptions {
  margin: 10px 0;
}

.roleTitle {
  cursor: pointer;
  user-select: none;
}

.lists {
  overflow: auto;
  padding-right: 15px;
}

.bookmarksCaption {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bookmarksList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5px 10px;
  border: 1px solid var(--button-primary-color);
  border-radius: 5px;
}

.listItem {
  list-style: none;
  width: 140px;
  padding: 2px 0;
  margin: 3px 0;
}

.listItemLabel {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.listItem:nth-child(4n + 1),
.listItem:nth-child(4n + 2) {
  background-color: var(--odd-row-color);
}

.input {
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
}
