.labelFilter {
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  width: 100%;
}

.disabled {
  opacity: 0.5;
}


.selected {
  background-color: var(--selected-filter-color);
  opacity: 1;
}

.caption {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled .title {
  opacity: 0.5;
}
.arrowReversed {
  transform: rotate(180deg);
}


.layoutTable {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 5px;
}

input[type="radio"] {
  cursor: pointer;
}
