.sidebar3,
.sidebar3 * {
  --border-radius: 6px;
  --border-width: 1px;
}

.sidebar3 {
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding-top: 5px;
}

.dropdownsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.dropdown {
  width: 100px;
  max-height: 200px;
  margin-left: 10px;
  z-index: 100;
}

.bookmarks {
  display: flex;
  align-items: center;
}

.bookmark {
  position: relative;
  top: var(--border-width);
  padding: 5px 10px;
  cursor: pointer;
  border: var(--border-width) solid transparent;
  user-select: none;
  min-width: 70px;
  text-align: center;
}

.content {
  width: 100%;
  border: var(--border-width) solid var(--primary-color);
  border-radius: var(--border-radius);
  display: flex;
  flex-flow: column;
  min-height: var(--min-bookmark-height);
}

.contentWithMaxHeight {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}

.bookmarkBar {
  min-height: var(--min-bookmark-height);
  flex-grow: 1;
  padding: 8px 10px;
}

.scriptBar,
.monitorBar {
  border: 0;
  flex-grow: 1;
}
