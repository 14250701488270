.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.inputFile {
  width: 0;
  height: 0;
  position: absolute;
}

.fileName {
  grid-column: 1 / -1;
}

.demoCallLoad {
  grid-column: 1 / -1;
}
