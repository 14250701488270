.tagsBar,
.tagsBarEmpty {
  padding: 8px 10px;
}

.tagsBarEmpty {
  display: flex;
  flex-flow: column;
}

.tagsBarUnhandled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sendForProcess {
  max-width: 25%;
}

.commonInfo {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: minmax(auto, 60%) auto;
  justify-content: space-between;
  grid-auto-flow: row dense;
}

.commonInfo h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.commonInfoValue {
  font-weight: normal;
  margin-left: 10px;
}

.column1 {
  grid-column: 1;
}

.column2 {
  grid-column: 2;
}

.column4 {
  grid-column: 4;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 2px;
  column-gap: 5px;
  align-items: center;
}

.autoInfo {
  display: grid;
  grid-template-columns: minmax(12px, 1fr) auto 50px auto;
  align-items: center;
  background-color: #00acef10;
}

.percent {
  color: gray;
}

.manualCheckbox {
  cursor: pointer;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.saveBtn {
  margin-top: 10px;
  width: 20%;
}

.resaveBtn {
  max-width: 200px;
  margin-left: auto;
  margin-top: auto;
}
