.dropdownFilter,
.droopdownFilter * {
  --border-radius: 6px;
  --font-size: inherit;
}

.dropdownFilter {
  /* https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout */
  min-width: 0;
  position: relative;
  border-radius: var(--border-radius);
}

.selected {
  background-color: var(--selected-filter-color);
}

[data-disabled=true] {
  opacity: 0.5;
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid;
  padding: 0 5px;
  border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.searchIcon {
  flex-shrink: 0;
}

.input {
  width: 100%;
  border-radius: var(--border-radius);
  border: 0;
  padding: 5px;
  padding-bottom: 6px;
  flex-grow: 1;
  font-size: var(--font-size);
  background-color: inherit;
}

.input:focus {
  outline: none;
}

.list {
  list-style-type: none;
  padding: 5px;
  border: 1px solid;
  border-top: 0;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  max-height: var(--max-dropdown-list-height);
  overflow-y: auto;
  font-size: var(--font-size);
  background-color: #fff;
  position: absolute;
  width: 100%;
}

.listItem {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listItem:hover {
  background-color: #00000010;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 5px;
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  cursor: pointer;
  user-select: none;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  cursor: pointer;
  flex-shrink: 0;
}

.arrowReversed {
  transform: rotate(180deg);
}

.copy {
  width: 12px;
  height: 12px;
  opacity: 0;
  margin-left: auto;
  margin-right: 10px;
}

.header:hover .copy {
  opacity: 1;
  cursor: copy;
}
