.commonInfo {
  display: flex;
  justify-content: space-between;
}

.selection {
  flex: 1 1 30%;
  max-height: unset;
}

.info {
  flex: 1 0 70%;
  padding-left: 10px;
  max-width: 70%;
}

.title {
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-content-width);
  margin-bottom: 3px;
}

.titleValue {
  font-weight: normal;
  margin-left: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
