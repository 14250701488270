.wrapper {
  padding: 8px 10px;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
}

.dictionarySelection {
  display: grid;
  grid-template-columns: max-content 1fr minmax(150px, 200px) repeat(2, 40px);
  grid-template-areas: "title . select add remove";
  align-items: center;
  padding-bottom: 10px;
  column-gap: 10px;
}

.selectTitle {
  grid-area: title;
}

.dropdownDictionary {
  grid-area: select;
}

.addBtn {
  grid-area: add;
}

.removeBtn {
  grid-area: remove;
}

.selectedDicts {
  margin-bottom: 10px;
}

.dictList, .dictList > * {
  --row-gap: 3px;
}

.dictList {
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-height: var(--max-button-height);
  border: 1px solid;
  padding: var(--row-gap) 5px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.dictList > * {
  margin-right: 20px;
  margin-top: var(--row-gap);
  margin-bottom: var(--row-gap);
}

.rows {
  border-top: 1px solid var(--primary-color);
  padding-top: 5px;
  overflow: auto;
  flex-grow: 1;
}

.row:not(:last-child) {
  margin-bottom: 10px;
}

.speaker {
  margin-bottom: 10px;
}

.abonent {
  background-color: #00acef10;
  border-radius: 6px;
}
