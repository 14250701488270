.app {
  display: flex;
  min-height: 100vh;
  padding-right: 10px;
}

.sidebar1Wrapper {
  flex: 1 1 30%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: var(--max-sidebar-width);
  max-height: 100vh;
  border-right: 5px solid #00acef;
  z-index: 1;
}

.sidebar1 {
  flex-grow: 1;
  overflow: auto;
}

.auth {
  align-self: center;
  max-width: 80%;
  width: 100%;
  flex-grow: 1;
}

.selectionDB {
  flex-grow: 1;
  width: 100%;
  align-self: center;
  max-height: 100vh;
  max-width: 80%;
}

.sidebar2 {
  flex: 1 1 15%;
}

.sidebar3Wrapper {
  flex: 1 1 40%;
  overflow: auto;
  max-height: calc(100vh - 10px);
  display: flex;
  flex-direction: column;
}

.sidebar3 {
  flex-grow: 1;
}
