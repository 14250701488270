.disabled {
  opacity: 0.5;
}

.captions {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr auto 1fr;
  align-items: center;
  gap: 10px;
}

.wordsList {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 10px;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}

.wordsListTitle {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.wordControls {
  display: grid;
  grid-template-columns: 150px minmax(auto, 200px) repeat(2, 30px);
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.input {
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
}

.loadDictsBtn {
  max-width: 200px;
  margin-top: 20px;
}
