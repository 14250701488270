.chartsBar {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chartsBar[data-disabled="true"] {
  opacity: 0.7;
  pointer-events: none;
}

.callSelectors {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  max-height: var(--max-button-height);
  font-size: 14px;
  z-index: 10;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.speechAnalysis {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.statusWrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveDeal {
  display: flex;
  font-size: 0.83em;
}

.saveDealText {
  margin-left: 5px;
  user-select: none;
}

.status {
  margin-left: auto;
}

.comment {
  margin-top: 5px;
  align-self: flex-end;
}

.speechChart {
  flex: 4 1 auto;
  margin-bottom: 10px;
}

.tempoChart {
  flex: 2 1 auto;
}

.audioPlayer {
  flex: 2 1 50px;
  width: 100%;
}

.dialogChart {
  flex-grow: 1;
}

.unhandledTalk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.unhandledTalkDesc {
  grid-column: 1 / 3;
}

.unhandledTalkAudioPlayer {
  grid-column: 1/4;
  width: 100%;
}
