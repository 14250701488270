.calendar {
  --cellWidth: 25px;
  --cellHeight: 25px;
  --highlightColor: green;

  display: grid;
  grid-template-columns: repeat(7, var(--cellWidth));
  grid-auto-rows: var(--cellHeight);
  column-gap: 12px;
  row-gap: 5px;
  justify-items: center;
  font-size: 14px;
  font-weight: 400;
}

.date {
  cursor: pointer;
  border-radius: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.calendar[data-handle-hover="true"] .date:hover {
  background-color: var(--button-primary-disabled-color);
}

.weekdays {
  color: #000000;
}

.weekdays.currentMonth {
  color: #000000;
}

.weekdays.otherMonth {
  color: #b1b1b1;
}

.weekends {
  color: #b82626;
}

.weekends.otherMonth {
  opacity: 0.5;
}

.calendar .date.dateHighlighted {
  color: white;
  background-color: var(--highlightColor);
}

.calendar .date.dateHighlighted {
  color: white;
  background-color: var(--highlightColor);
}

.calendar[data-handle-hover="true"] .date:hover {
  color: black;
  background-color: var(--button-primary-disabled-color);
}

.calendar .date.dateSelected {
  color: white;
  background-color: #00acef;
}

.calendar .date.dateFromSelected {
  color: white;
  background-color: #00acef;
}

/* выделение вперед */

[data-show-range="true"][data-direction="forward"] .nextMonthStartSelection,
[data-show-range="true"][data-direction="forward"] .nextMonthStartSelection ~ span,
[data-show-range="true"][data-direction="forward"] .dateFromSelected ~ span {
  background-color: var(--button-primary-disabled-color);
}

[data-show-range="true"][data-direction="forward"] .dateToSelected ~ span {
  background-color: initial;
}

[data-show-range="true"][data-direction="forward"] .dateToSelected ~ .dateHighlighted {
  background-color: var(--highlightColor);
}

[data-show-range="true"][data-direction="forward"] span.dateToSelected,
[data-handle-hover="true"][data-show-range="true"][data-direction="forward"] .date:hover {
  color: white;
  background-color: #00acef;
}

[data-handle-hover="true"][data-show-range="true"][data-direction="forward"] .date:hover ~ span {
  background-color: initial;
}

[data-handle-hover="true"][data-show-range="true"][data-direction="forward"] .date:hover ~ .dateHighlighted {
  background-color: var(--highlightColor);
}

/* выделение назад */

[data-handle-hover="true"][data-show-range="true"][data-direction="backward"] .date:hover ~ span {
  background-color: var(--button-primary-disabled-color);
}

[data-handle-hover="true"][data-show-range="true"][data-direction="backward"] .date:hover,
[data-handle-hover="true"][data-show-range="true"][data-direction="backward"] span.date.dateFromSelected {
  color: white;
  background-color: #00acef;
}

[data-handle-hover="true"][data-show-range="true"][data-direction="backward"] .date.dateFromSelected ~ span {
  background-color: initial;
}

[data-handle-hover="true"][data-show-range="true"][data-direction="backward"] .dateFromSelected ~ span.dateHighlighted {
  background-color: var(--highlightColor);
}
