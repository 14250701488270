.fieldset,
.fieldset * {
  --font-size: 14px;
  --color-error: #ca2626;
}

.fieldset {
  border-radius: 5px;
  border: 1px solid transparent;
  width: 100%;
}

.label {
  border-radius: inherit;
  user-select: none;
  position: relative;
}

.legend {
  margin-left: 15px;
  padding: 0 6px;
  color: var(--button-primary-color);
  font-weight: 400;
  font-size: 12px;
  display: none;

  height: 0;
  position: relative;
  z-index: 1;
  top: calc(var(--font-size) / -2);
}

.input {
  width: 100%;
  padding: 10px;

  border: 0;
  font-size: var(--font-size);
  padding-right: 40px;
  background: var(--button-primary-color);
  border-radius: inherit;
  color: white;
}

.input::placeholder {
  color: white;
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.input:focus {
  outline: none;
  background-color: #fff;
  color: var(--button-primary-color);
}

.input ~ svg.icon path {
  stroke: white;
}

.input:focus ~ svg.icon path {
  stroke: var(--button-primary-color);
}

.input:focus::placeholder {
  opacity: 0;
}

.fieldset:focus-within {
  border-color: var(--button-primary-color);
}

.fieldset:focus-within .legend {
  display: initial;
}

.error.fieldset {
  border-color: var(--color-error);
  position: relative;
}

.errorMessage {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 12px;
  color: var(--color-error);
}

.error .legend {
  color: var(--color-error);
  visibility: visible;
}

.error .input {
  background-color: transparent;
  color: var(--color-error);
}

.error .icon path {
  stroke: var(--color-error);
}

.error .input ~ svg.icon path {
  stroke: var(--color-error);
}
