.selectors {
  display: flex;
  z-index: 0;
  font-size: 14px;
  margin-top: 10px;
}

.selectors > *:not(:last-child) {
  margin-bottom: 10px;
}

.searchByCallIdWrapper,
.tableId {
  display: grid;
  grid-template-columns: 50% 48%;
  align-items: center;
  justify-content: space-between;
}

.searchByCallId {
  border-color: red;
}

.dropdownProject,
.dropdownOperator,
.dropdownSetId,
.tableId {
  width: 100%;
}

.dropdownSetId {
  z-index: 15;
}

.dropdownProject {
  z-index: 10;
}

.dropdownOperator {
  z-index: 5;
}

.dropdownStatus {
  z-index: 4;
  width: 100%;
}

.dropdownSubStatus {
  z-index: 3;
  width: 100%;
}

.wordsSearchWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 14px;
}

.wordsTitle {
  margin-right: 10px;
}

.wordsInput {
  flex-grow: 1;
}

.wordsInputNonEmpty {
  background-color: var(--selected-filter-color);
}

.labelFilterWrapper {
  position: relative;
  min-height: 29px;
  width: 100%;
  z-index: 2;
}

.labelFilter {
  background: #fff;
  position: absolute;
}

.loader :local {
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dealSelectionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  min-height: 40px;
}

.dealSelection {
  flex: 1 0 48%;
  max-width: 48%;
}

.dealSelection [class*="header"],
.dealSelection [class*="search"],
.dealSelection [class*="list"] {
  border-color: red;
}

.controls {
  display: grid;
  grid-template-columns: repeat(2, 48%);
  justify-content: space-between;
}

.showChartBtn,
.changeDB,
.exit {
  max-height: var(--max-button-height);
}

.lastRow {
  display: grid;
  grid-template-columns: 48% 48% 0%;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
  position: relative;
}

.datePicker {
  grid-column: 2;
}

.calendar {
  bottom: -20px;
}

.datePickerLauncher {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.datePickerLauncher[data-disabled="true"] {
  opacity: 0.5;
}

.datePickerWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
