.leadsBar {
  padding: 8px 10px;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 15px;
  row-gap: 7px;
  align-items: center;
  margin-top: 10px;
}

.listItem,
.listItemReduced {
  all: unset;
  display: grid;
  grid-template-columns: auto 4ch auto 1fr;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
}

.listItemReduced {
  grid-template-columns: auto 1fr;
}

.listItemInput {
  cursor: pointer;
}

.listItemTitle {
  cursor: pointer;
  margin-left: 5px;
}

.controlsWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveBtn,
.controlBtn, 
.resetBtn {
  max-width: 120px;
}
