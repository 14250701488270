.inputSearch {
  border: 1px solid;
  display: flex;
  border-radius: 6px;
  padding: 2px;
  max-height: var(--max-button-height);
}

.inputSearchDisabled {
  opacity: 0.5;
}

.input {
  width: 100%;
  padding: 5px;
  border-radius: inherit;
  border: 0;
  outline: none;
  cursor: text;
  font-size: 14px;
}

.inputDisabled {
  color: #000;
}

.selected {
  background-color: var(--selected-filter-color);
}

.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
