.sidebar1 {
  display: flex;
  flex-direction: column;
}

.basicSelection {
  max-width: var(--max-content-width);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.basicSelection::-webkit-scrollbar {
  width: 0;
}

.charts {
  flex-grow: 1;
}