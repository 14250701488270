.wordsBar {
  padding: 8px 10px;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
}

.dictionarySelection {
  display: grid;
  grid-template-columns: max-content minmax(150px, 200px);
  align-items: center;
  justify-content: space-between;
}

.togglers {
  display: grid;
  grid-template-columns: repeat(5, auto);
  border-bottom: 1px solid var(--primary-color);
  padding: 5px 0;
}

.toggler {
  margin-right: 5px;
  cursor: pointer;
}

.metrics {
  padding: 5px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-left: 50px;
  margin-right: var(--scrollbar-width);
  justify-items: center;
}

.rows {
  border-top: 1px solid var(--primary-color);
  padding-top: 5px;
  overflow: auto;
  flex-grow: 1;
}

.row:not(:last-child) {
  margin-bottom: 10px;
}

.abonent {
  background-color: #00acef10;
  border-radius: 6px;
}

.speaker {
  margin-bottom: 10px;
}

.titles {
  display: grid;
  grid-template-columns: 50px repeat(5, 1fr);
}

.metricInput {
  width: 24px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid;
}

.metricInput:not(:last-child) {
  margin-right: 5px;
}

.metricInputsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
