.filterBar,
.filterBar * {
  --border-radius: 6px;
  --border-width: 1px;
}

.filterBar {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "ids project"
    "status operator"
    "dates duration"
    "stages stages"
    "buttons response";
  align-items: center;
  gap: 5px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ids {
  grid-area: ids;
  display: grid;
  grid-template-columns: max-content max-content auto;
  gap: 5px;
}

.rowTitle {
  flex: 1 1 30%;
}

.callIdInput,
.durationInput {
  padding: 5px;
  border-radius: var(--border-radius);
  border: var(--border-width) solid;
  outline: none;
  cursor: text;
  flex: 1 1 60%;
  font-size: 14px;
  font-family: var(--font-family-primary);
}

.datesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.datePickerWrapper {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
}

.dateInput {
  padding: 5px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
}

.dateInputEmpty {
  color: grey;
}

.callIdInput {
  max-width: 100px;
}

.durationInput {
  max-width: 70px;
}

.response {
  grid-area: response;
  display: grid;
  grid-template-columns: 100px max-content;
  align-items: center;
  gap: 10px;
}

.buttonsContainer {
  grid-area: buttons;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.dropdownOperator,
.dropdownStatus,
.dropdownProject {
  font-size: 14px;
}

.dropdownProject {
  grid-area: project;
  z-index: 20;
}

.dropdownStatus {
  grid-area: status;
  z-index: 15;
}

.dropdownOperator {
  grid-area: operator;
  z-index: 15;
}

.dates {
  grid-area: dates;
}

.pretext {
  padding: 0 5px;
}

.duration {
  grid-area: duration;
  align-items: center;
}

.datePicker {
  min-width: 105px;
}

.stages {
  grid-area: stages;
  display: grid;
  grid-template-columns: 1fr repeat(3, 100px) 2fr;
}

.stageValue {
  margin-left: 5px;
  cursor: pointer;
}

.upload {
  cursor: pointer;
  border: 1px solid var(--button-primary-color);
  border-radius: 5px;
  padding: 5px;
  max-height: var(--max-button-height);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.uploadDesc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
