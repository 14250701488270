.selection {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.baseButtons {
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
}

.logoutBtn,
.base1,
.base2,
.base3 {
  max-height: unset;
}

.logoutBtn {
  margin-top: 20px;
  margin-bottom: auto;
}

.base2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.base3 {
  margin-bottom: auto;
  animation: spin 1s 0 infinite;
}
