.message,
.message * {
  --border-radius: 6px;
  --box-shadow-color: #00000025;
  --background-color: #ffffff;
}

.message {
  position: relative;
  padding: 8px;
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: -2px 3px 2px var(--box-shadow-color),
    -2px -2px 2px var(--box-shadow-color), 3px -1px 2px var(--box-shadow-color),
    2px 3px 4px var(--box-shadow-color);
}

.message::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: inherit;
  bottom: -4px;
  left: 8px;
  transform: rotate(90deg) skew(45deg);
  box-shadow: 2px 2px 1px var(--box-shadow-color),
    2px 2px 1px var(--box-shadow-color);
}

.messageOut::after {
  height: 7px;
  right: 8px;
  bottom: -3px;
  left: auto;
  transform: rotate(-90deg) skew(-45deg);
  box-shadow: -2px 2px 1px var(--box-shadow-color),
    -3px 2px 2px var(--box-shadow-color);
}
