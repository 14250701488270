.wrapper {
  gap: 10px 10px;
  padding: 8px 10px;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
}

.wrapperDisabled {
  user-select: none;
  opacity: 0.7; 
}

.caption {
  display: grid;
  grid-template-columns: 30px auto auto;
  grid-template-areas: ". plusMinus power";
}

.plusMinus,
.power {
  text-align: center;
  padding: 3px 3px;
}

.plusMinus {
  grid-area: plusMinus;
}

.power {
  grid-area: power;
}

.rows {
  border-top: 1px solid var(--primary-color);
  padding-top: 5px;
  overflow: auto;
  flex-grow: 1;
}

.row {
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  gap: 0 10px;
  margin-bottom: 10px;
}

.abonent {
  background-color: #00acef10;
  border-radius: 6px;
}

.text {
  grid-column: 2/4;
  margin-bottom: 5px;
}

.speaker {
  grid-column: 1;
  text-align: center;
}

.plusMinusRange,
.powerRange {
  display: flex;
  flex-direction: column;
}

.plusMinusRange {
  grid-column: 2;
  display: grid;
  grid-template-columns: 60px 1fr;
}

.plusMinusValue {
  grid-row: 1/3;
  grid-column: 1;
  display: flex;
  align-items: center;
}

.powerRange {
  grid-column: 3;
  display: grid;
  grid-template-columns: 1fr 60px;
}

.powerRangeValue {
  padding-left: 5px;
  grid-row: 1/3;
  grid-column: 2;
  display: flex;
  align-items: center;
}

.saveBtn {
  width: 30%;
  margin-left: 40px;
}

.controls {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
