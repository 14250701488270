.wrapper {
  padding: 8px 10px;
  display: grid;
  grid-template-columns: 1fr 110px;
  column-gap: 5px;
  align-content: start;
}

.wrapperDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.list {
  display: grid;
  grid-template-columns: min-content minmax(80px, 1fr) 50px minmax(80px, 1fr) 50px;
  grid-auto-rows: 25px;
  column-gap: 10px;
  align-items: center;
  grid-auto-flow: column dense;
  row-gap: 5px;
}

.titleEmotion {
  grid-row: 1;
  grid-column: 1;
}

.titleOperator {
  grid-column: 2 / 4;
  grid-row: 1;
}

.titleAbonent {
  grid-column: 4 / 6;
  grid-row: 1;
}

.titleEmotion,
.titleOperator,
.titleAbonent {
  padding: 10px 0;
}

.emoName {
  grid-column: 1;
}

.operatorInput {
  grid-column: 2;
}

.operatorValue {
  grid-column: 3;
}

.abonentInput {
  grid-column: 4;
}

.abonentValue {
  grid-column: 5;
}

.column2 {
  display: flex;
  flex-direction: column;
}
.column2 :not(:last-child) {
  margin-bottom: 5px;
}

.column2 :last-child {
  margin-top: auto;
}

.methodToggler {
  cursor: pointer;
}

.charts {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.chart {
  flex-grow: 1;
  min-height: 100px;
  max-height: 150px;
}

.controlBtn {
  margin-top: 10px;
  max-width: 120px;
}
