.wrapper {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: grid;
  grid-template-rows: auto 3fr auto 4fr 150px;
  gap: 10px;
  height: 100vh;
}

.title {
  font-size: 24px;
}

.dealList,
.dealInfo {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  word-break: break-word;
  min-height: 50px;
}

.dealList {
  overflow-y: auto;
  max-height: 40vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: start;
}

.dealItem {
  display: block;
}

.dealId {
  margin-right: 5px;
  cursor: pointer;
}

.controls {
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
}

.controlsAdmin {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-between;
  gap: 10px;
}

:only-child.controlBtn,
.downloadJSON {
  grid-column: 1 / -1;
}

.commentSaver {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.comment {
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  padding: 10px;
  resize: none;
  outline-color: var(--primary-color);
  font-family: var(--font-family-primary);
  font-size: 14px;
  flex-grow: 1;
}

.saveCommentBtn {
  margin-top: 10px;
}