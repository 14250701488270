.dropdown,
.droopdown * {
  --transition-duration: 0.3s;
  --border-radius: 6px;
  --font-size: 14px;
}

.dropdown {
  /* https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout */
  min-width: 0;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  padding: 5px;
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  cursor: pointer;
  user-select: none;
}

.value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.header_expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}

.arrow {
  cursor: pointer;
  transition: var(--transition-duration);
}

.arrowReversed {
  transform: rotate(180deg);
}

.list {
  margin: 0;
  list-style-type: none;
  padding: 5px;
  border: 1px solid var(--primary-color);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  overflow-y: auto;
  font-size: var(--font-size);
  background-color: #fff;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}

.listItem {
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px;
}

.listItem:hover {
  background-color: #00000010;
}
