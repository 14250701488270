.wrapper {
  padding: 8px 10px;
  padding-top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.statsChartsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.saveBtn {
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.datesRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.datesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.datePickerWrapper {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 5;
}

.dateInput {
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
}

.dateInputEmpty {
  color: grey;
}

.pretext {
  padding: 0 5px;
}

.resetRange,
.request {
  min-width: 120px;
  margin-left: 10px;
}

.statsCaption {
  display: flex;
  margin-bottom: 10px;
}

.statsGrid {
  display: grid;
  grid-template-columns: min-content repeat(2, auto) 1fr;
  padding: 10px 0;
  gap: 10px;
  align-items: center;
}

.statsCaption :first-child {
  margin-right: 20px;
}

.chartsCaption {
  margin: 20px 0 10px;
}

.rowsWrapper {
  display: grid;
  grid-template-columns: 100px repeat(2, auto) 1fr auto;
  gap: 10px;
  margin-top: 10px;
}

.total,
.totalTitle {
  grid-column: 2;
}

.average,
.averageTitle {
  grid-column: 3;
}

.errorSource,
.statsTitle {
  grid-column: 1;
}

.errorDescWrapper {
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 10px;
  align-self: start;
}

.chart {
  grid-column: 4;
}

.recognitionChart {
  grid-row: 2 / 7;
}

.reprocessBtn {
  grid-column: span 3;
}

.reprocessExplanation {
  grid-column: 4 / -1;
  align-self: center;
}

.invalidCalls {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 150px;
  padding-bottom: 8px;
}

.invalidCallsTitle {
  font-weight: 600;
  margin-bottom: 20px;
}

.invalidCallsValue {
  font-weight: normal;
  margin-left: 20px;
}
