.masksBarDisabled {
  opacity: 0.5;
  user-select: none;
}

.projectControls {
  display: grid;
  grid-template-columns: auto 2fr 1fr;
  align-items: center;
  gap: 10px;
}

.dropdownProject {
  font-size: 14px;
  z-index: 30;
}

.radiosWrapper {
  margin-top: 10px;
}

.radioLabel {
  margin-right: 20px;
  cursor: pointer;
}

.radioLabelText {
  margin-left: 5px;
}

.tagsCaption {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.tagsFilterText {
  margin-left: 5px;
}

.tagsInfo {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: start;
  column-gap: 15%;
}

.tagsInfoItem {
  list-style: none;
}

.tagsInfoLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.tagsInfoInput {
  margin-left: 10px;
}

.controls {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 10px;
}

.addTag {
  max-width: 150px;
}

.saveBtn {
  max-width: 200px;
}

.filterByTagSelection {
  margin-top: 15px;
  display: grid;
  grid-template-columns: auto 1fr repeat(2, 30px);
  align-items: center;
  gap: 10px;
}

.filterByTagRadios {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.selectedTags {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
}

.selectedTagsList {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  min-height: var(--max-button-height);
}
