.datePicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 130px;
  user-select: none;
  position: relative;
}

.prevDay,
.nextDay,
.prevMonth,
.nextMonth {
  cursor: pointer;
}

.prevDay path,
.nextDay path,
.prevMonth path,
.nextMonth path {
  stroke: #6c4c40;
}

.prevDay {
  transform: rotate(90deg);
}

.nextDay {
  transform: rotate(-90deg);
}

.calendarWrapper {
  user-select: none;
  padding: 18.5px 15px 15px 15px;
  border: 1px solid #d9c8c0;
  border-radius: 5px;
  background-color: #ffffff;
}

.controls {
  display: grid;
  grid-template-areas: "month year prevMonth . nextMonth";
  grid-template-columns: auto auto minmax(auto, 17px) 40px minmax(auto, 17px);
  align-items: center;
  padding-bottom: 20px;
}

.month {
  grid-area: month;
  font-weight: bold;
}

.year {
  grid-area: year;
}

.prevMonth {
  grid-area: prevMonth;
  transform: rotate(90deg);
}

.nextMonth {
  grid-area: nextMonth;
  transform: rotate(-90deg);
  justify-self: end;
}
