.wrapper, .wrapper * {
  --audio-player-max-height: 45px;
}

.wrapper {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.status {
  margin-top: 10px;
  text-align: end;
}

.comment {
  margin-top: 5px;
  text-align: end;
}

.audioPlayer {
  margin-top: 10px;
  width: 100%;
  max-height: var(--audio-player-max-height);
  flex: 0 0 auto;
}

.dialogs {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #00acef;
  border-radius: 6px;
  padding: 10px;
  overflow-y: auto;
}

.client,
.operator {
  max-width: 90%;
  margin-bottom: 10px;
}

.operator {
  align-self: flex-end;
}

.messageStart {
  margin-top: 3px;
  font-size: 10px;
  color: green;
}
